
export const SET_PROPERTY = 'setPropertyData';

// Content
export const RESET_CONTENT = 'resetContent';
//리스트
export const SET_CONTENT_FILTER = 'setContentFilterData';
export const SET_CONTENT_CATE_INDEX = 'setContentCateIndex';
export const PUSH_CONTENT_LIST = 'pushContentList';
export const RESET_CONTENT_LIST = 'resetContentList';
export const SET_CONTENT_RESPONSE = 'setContentResponse';
export const SET_CONTENT_NEXTPAGE = 'setContentNextPage';
// 검색
export const SET_CONTENT_SEARCH_KWD = 'setContentSearchKeyword';
// 상세
export const SET_CONTENT_DETAIL_TAB = 'setContentDetailTab';
export const SET_CONTENT_DETAIL_ITEM = 'setContentDetailItem';
export const RESET_CONTENT_DETAIL_ITEM = 'resetContentDetailItem';

// Outside2D
export const RESET_OUTSIDE2D = 'resetOutside2D';
export const RESET_OUTSIDE2D_DETAIL = 'resetOutside2DDetail';
export const SET_OUTSIDE2D_DETAIL_TAB = 'setOutsideDetailTab';
export const SET_OUTSIDE2D_DETAIL_ITEM = 'setOutsideDetailItem';

//Museum
export const RESET_MESEUM = 'resetMeseum';
// 박물관 메인
export const SET_MUSEUM_MAIN_LOCATION = 'pushMuseumMainLocation';
export const SET_MUSEUM_MAIN_AGE = 'pushMuseumMainAge';
export const SET_MUSEUM_MAIN_TAB = 'setMuseumMainTab';
// 박물관 리스트
export const SET_MUSEUM_LOCATION_TAB = 'pushMuseumLocationTab';
export const RESET_MUSEUM_LOCATION_LIST = 'resetMuseumLocationList';
export const PUSH_MUSEUM_LOCATION_LIST = 'pushMuseumLocationList';
export const SET_MUSEUM_LOCATION_RESPONSE = 'setMuseumLocationResponse';
export const SET_MUSEUM_LOCATION_NEXTPAGE = 'setMuseumLocationNextPage';
//검색
export const SET_MUSEUM_SEARCH_KWD = 'setMuseumSearchKeyword';
export const RESET_MUSEUM_SEARCH = 'resetMuseumSearch';
export const PUSH_MUSEUM_SEARCH_LIST = 'pushMuseumSearchList';
export const SET_MUSEUM_SEARCH_RESPONSE = 'setMuseumSearchResponse';
export const SET_MUSEUM_SEARCH_NEXTPAGE = 'setMuseumSearchNextPage';
export const RESET_MUSEUM_SEARCH_LIST = 'resetMuseumSearchList';

// 박물관 상세
export const SET_MUSEUM_DETAIL = 'setMuseumDetail';
export const SET_MUSEUM_DETAIL_TAB = 'setMuseumDetailTab';
export const SET_MUSEUM_DETAIL_CONTENT_RESPONSE = 'setMuseumDetailContentResponse';
export const SET_MUSEUM_DETAIL_CONTENT_NEXTPAGE = 'setMuseumDetailContentNextPage';
export const RESET_MUSEUM_DETAIL = 'resetMuseumDetail';
export const PUSH_MUSEUM_DETAIL_CONTENT_LIST = 'setMuseumDetailContentList';


// Collection
export const RESET_COLLECTION = 'resetCollection';
export const RESET_COLLECTION_DETAIL = 'resetCollectionDetail';

// collect 메인
export const SET_COLLECTION_LIST = 'pushCollectionList';
export const SET_COLLECTION_CATEGORY = 'pushCollectionCatetory';
export const SET_COLLECTION_TAB = 'setCollectionTab';

// collection 상세
export const SET_COLLECTION_DETAIL = "setCollectionDetail";

// 홀로그램
export const SET_HOLOGRAM_DATA = 'setHologramData';
export const SET_HOLOGRAM_DETAIL = 'setHologramDetail';


// User
export const SET_LOGIN_USER = 'setLoginUser';
export const SET_LOGOUT = 'setLogout';