import { PagingEntry } from "@/entry/PagingEntry";
import { FilterEntry } from "@/entry/FilterEntry";
import {
  DATA_RESET,
  FETCH_CONTENT_LIST,
  FETCH_CONTENT_DETAIL
} from "@/store/action.type";
import {
  RESET_CONTENT,
  SET_CONTENT_FILTER,
  SET_CONTENT_DETAIL_TAB,
  PUSH_CONTENT_LIST,
  SET_CONTENT_CATE_INDEX,
  RESET_CONTENT_LIST,
  SET_CONTENT_RESPONSE,
  SET_CONTENT_NEXTPAGE,
  SET_CONTENT_DETAIL_ITEM,
  SET_CONTENT_SEARCH_KWD,
  RESET_CONTENT_DETAIL_ITEM,
} from "@/store/mutations.type";

import {
  CONTENT_LIST_CATEINDEX,
  CONTENT_DETAIL_ITEM
} from "@/store/getter.type";

import {
  ContentService,
  CheckApi
} from "@/network/api.service";

const state = {
  // 컨텐츠 리스트 페이징
  content: new PagingEntry(),
  // 컨텐츠 검색 키워드
  content_keyword: null,
  // 컨텐츠 필터 데이터
  filter: new FilterEntry(),
  // 컨텐츠 카테고리 Index
  selectCateIndex: 1,
  // 컨텐츠 상세 Tab Index
  selectTabIndex: 1,
  // 컨텐츠 상세 아이템
  content_item: new Object()
}

const mutations = {
  [RESET_CONTENT](state) {
    // 초기화용
    const reset_state = {
      // 컨텐츠 리스트 페이징
      content: new PagingEntry(),
      // 컨텐츠 검색 키워드
      content_keyword: null,
      // 컨텐츠 필터 데이터
      filter: new FilterEntry(),
      // 컨텐츠 카테고리 Index
      selectCateIndex: 1,
      // 컨텐츠 상세 Tab Index
      selectTabIndex: 1,
    };
    Object.assign(state, reset_state)
  },
  [SET_CONTENT_FILTER](state, payload) {
    // console.log(payload);
    Object.assign(state.filter, payload)
    // state.filter.sort = payload.sort;
    // state.filter.order = payload.order;
    // state.filter.anim = payload.anim;
    // state.filter.down = payload.down;
  },
  [SET_CONTENT_CATE_INDEX](state, payload) {
    state.selectCateIndex = payload;
  },
  [SET_CONTENT_DETAIL_TAB](state, payload) {
    state.selectTabIndex = payload;
  },
  [PUSH_CONTENT_LIST](state, payload) {
    state.content.list = state.content.list.concat(payload);
  },
  [SET_CONTENT_RESPONSE](state, payload) {
    state.content.lastResponse = payload;
  },
  [SET_CONTENT_NEXTPAGE](state, payload) {
    state.content.nextPageNum = payload;
  },
  [SET_CONTENT_SEARCH_KWD](state, payload) {
    state.content_keyword = payload;
  },
  [RESET_CONTENT_LIST](state, payload) {
    // 리플레쉬 할경우에 리스트 데이터 초기화
    state.content.list = new Array();
    state.content.lastResponse = null;
    state.content.nextPageNum = 1;
  },
  [SET_CONTENT_DETAIL_ITEM](state, payload) {
    state.content_item = payload;
  },
  [RESET_CONTENT_DETAIL_ITEM](state) {
    state.outside2dDetail = {};
  },
}

const actions = {
  [DATA_RESET]: {
    handler({ commit }) {
      commit(RESET_CONTENT);
    }
  },

  [FETCH_CONTENT_LIST]({ commit, state }, params) {

    return new Promise((resolve, reject) => {
      ContentService.getList(params.major, params.minor, params.age_cd, params.filter, params.kwd, state.content.nextPageNum).then(res => {

        if (!CheckApi.check(res)) {
          reject(res.data);
          return;
        }

        if (res.data.contents3dmodel_list) {
          commit(SET_CONTENT_RESPONSE, res.data);
          commit(PUSH_CONTENT_LIST, res.data.contents3dmodel_list);
          commit(SET_CONTENT_NEXTPAGE, state.content.nextPageNum + 1);
        }

        resolve(res.data)
      }).catch(error => {
        console.log('# error(FETCH_CONTENT_LIST):', error);
        reject(error);
      });
    });
  },

  [FETCH_CONTENT_DETAIL]({ commit, state }, params) {

    return new Promise((resolve, reject) => {
      ContentService.getDetailItem(params).then(res => {

        if (!CheckApi.check(res)) {
          reject(res.data);
          return;
        }

        commit(SET_CONTENT_DETAIL_ITEM, res.data);
        resolve(res.data)
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }
}
const getters = {
  [CONTENT_LIST_CATEINDEX](state, getters) {
    return state.selectCateIndex;
  },
  // [CONTENT_DETAIL_ITEMS]: (state, getters) => (id) => {
  //   console.log("[GET] CONTENT_DETAIL_ITEMS id " + id);
  //   if (!state.content_items[id]) {
  //     state.content_items[id] = new Object();
  //   }
  //   console.log(state.content_items[id]);
  //   return state.content_items[id];
  // }
  [CONTENT_DETAIL_ITEM](state, getters) {
    return state.content_item;
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}