export const PROPERTY = "property";

//*** Content ***/
export const CONTENT_LIST_CATEINDEX = `contentListCateIndex`;
export const CONTENT_DETAIL_ITEM = `contentDetailItem`;

//*** Outside2D ***/
export const OUTSIDE2D_DETAIL_ITEM = `outside2dDetailItem`;

//*** Museum ***/
export const MUSEUM_MAIN_LOCATION = `museumMainLocation`;
export const MUSEUM_MAIN_AGE = `museumMainAge`;
export const MUSEUM_MAIN_SELECT_TAB = `museumMainTab`;

export const MUSEUM_LIST = `museumList`;
export const MUSEUM_LIST_SELECT_TAB = `museumListTab`;

export const MUSEUM_SEARCH_LIST = `museumSearchList`;
export const MUSEUM_SEARCH_KEYWORD = `museumSearchKeyword`;

export const MUSEUM_DETAIL = `museumDetail`;
export const MUSEUM_DETAIL_LIST = `museumDetailList`;
export const MUSEUM_DETAIL_SELECT_TAB = `museumDetailTab`;

//*** Collection ***/
export const COLLECTION_LIST = `collectionList`;
export const COLLECTION_CATEGORY = `collectionCategory`;
export const COLLECTION_SELECT_TAB = `collectionTab`;

export const COLLECTION_CATEGORY_DETAIL = `collectionDetail`;

//*** Hologram ***/
export const HOLOGRAM_DATA = `hologramData`;
export const HOLOGRAM_DETAIL = `hologramDetail`;


//*** User ***/
export const LOGIN_USER = `loginUser`;