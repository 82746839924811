import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/network/jwt.service";
import querystring from "querystring-es3";

export const API_URL = process.env.VUE_APP_SERVER_HOST;
export const CONTROLLER = "Ajax3DWorldController.do";
export const CONTROLLER_MULTIPART = "Ajax3DWorldMultipartController.do";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

    if (process.env.NODE_ENV !== 'production') {
      Vue.axios.interceptors.request.use(request => {
        console.log('Request', request)
        return request
      })

      Vue.axios.interceptors.response.use(response => {
        console.log('Response:', response)
        return response
      })
    }
  },

  setHeader() {
    if (JwtService.getToken() && JwtService.getToken().length > 0) {
      Vue.axios.defaults.headers.common[
        "Authorization"
        ] = "Token " + `${JwtService.getToken()}`;
    }
  },

  query(resource, params) {
    return Vue.axios.get(`${resource}`, params).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  post(resource, params) {
    if (JwtService.getToken() && JwtService.getToken().length > 0) {
      params.token_uuid = `${JwtService.getToken()}`;
    }
    var data = querystring.stringify({
      "jsondata": JSON.stringify(params)
    });
    return Vue.axios.post(`${resource}`, data);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  multipart(resource, params, files) {
    if (JwtService.getToken() && JwtService.getToken().length > 0) {
      params.token_uuid = `${JwtService.getToken()}`;
    }

    let formData = new FormData();
    formData.append('jsondata', JSON.stringify(params))

    console.log(formData);

    if (files) {
      files.forEach(item => {
        formData.append('file[]', item)
      });
    }

    return Vue.axios.post(`${resource}`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  }
};

export default ApiService;


export const CheckApi = {
  check(res) {

    if (!res.data)
      return false;

    if (res.data.retcode && res.data.retcode != "000") {
      // 로그인이 필요한 경우
      if (res.data.retcode == '990')
        self.$router.push({path: '/common/login'});
      return false;
    }

    return true;
  },
}


export const PropertyService = {
  // 프로퍼티 목록
  get() {
    var params = {
      "service_tp": "property_list"
    };
    return ApiService.post(CONTROLLER, params);
  }
};


export const ContentService = {
  // 콘텐츠 리스트
  getList(major, minor, age_cd, filter, kwd, page) {
    var params = {
      //"service_tp": "conts_3d_model_list",
      service_tp: 'conts_3d_model_with_topk_list',
      conts_major: !major ? "ALL" : major,
      conts_minor: !minor ? "ALL" : minor,
      age_cd: !age_cd ? "ALL" : age_cd,
      page_num: !page ? '1' : String(page),
      max_ret_count: '24',
    };

    if (params.conts_major == 'topk') {
      params.is_topklist = 'Y';
    }

    if (kwd) {
      params.conts_word = kwd;
    }

    if (filter) {
      params.filter_orderby_tp = String(filter.sort);
      params.filter_reg_tp = String(filter.order);
      //params.filter_any_yn = filter.anim ? 'Y' : 'N';
      params.f_anni_yn = filter.anim ? 'Y' : null;
      //params.filter_download_yn = filter.down ? 'Y' : 'N';
      params.pay_tp =
        (filter.pay_tp_1 && filter.pay_tp_2) ? '1,2' :
          filter.pay_tp_1 ? '1' :
            filter.pay_tp_2 ? '2' : null;
    }

    return ApiService.post(CONTROLLER, params);
  },

  // 컨텐츠 상세 데이터
  getDetailItem(id) {
    var params = {
      service_tp: 'api_conts_3d_info',
      conts_uid: id,
      isSetRecmd: 'Y',
    };

    return ApiService.post(CONTROLLER, params);
  },

  getContentCommentList(id, page) {
    var params = {
      "service_tp": "comment_list",
      "conts_uid": id,
      "page_num": page
    };

    return ApiService.post(CONTROLLER, params);
  },

  pushContentComment(obj) {
    var params = {
      "service_tp": "comment_reg",
      "conts_uid": obj.id,
      "contents": obj.contents,
      "crud_tp": obj.crud_tp
    };

    if (obj.pub_tp) {
      params.pub_tp = obj.pub_tp;
    }

    if (obj.cmt_seq) {
      params.cmt_seq = obj.cmt_seq;
    }

    return ApiService.post(CONTROLLER, params);
  },

  getContentComment(obj) {
    obj.crud_tp = 'r';
    return this.pushContentComment(obj);
  },
  deleteContentComment(obj) {
    obj.crud_tp = 'd';
    return this.pushContentComment(obj);
  }

};

export const Outside2DService = {
  // 콘텐츠 리스트
  getList(kwd, page) {
    let axiosConfig = {
      params: {
        __url: 'http://www.emuseum.go.kr/openapi/relic/list?' + $.param({
          serviceKey: 'lWwe3GjPnDDPudP6/g5EwqhbHecVtDlasgn8a/69kfhSD5ZH+3/iO1gIbGMsepTn8k2F0aW+CHBqMGrWVFH80Q==',
          pageNo: !page ? '1' : String(page),
          numOfRows: '20',
          name: kwd ? kwd : '',
        })
      },
    };
    const axios = require('axios');
    return new Promise((resolve, reject) => {
      //axios.get('http://localhost:13579/relay_get.api', axiosConfig)
      axios.get('https://www.3dbank.xyz:13578/relay_get.api', axiosConfig)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // 컨텐츠 상세 데이타
  getDetailItem(id) {
    console.log("aaaaaaaaaa ", id);
    let axiosConfig = {
      params: {
        __url: 'http://www.emuseum.go.kr/openapi/relic/detail?' + $.param({
          serviceKey: 'lWwe3GjPnDDPudP6/g5EwqhbHecVtDlasgn8a/69kfhSD5ZH+3/iO1gIbGMsepTn8k2F0aW+CHBqMGrWVFH80Q==',
          pageNo: 1,
          numOfRows: '20',
          id: id,
        })
      },
    };
    const axios = require('axios');
    return new Promise((resolve, reject) => {
      //axios.get('http://localhost:13579/relay_get.api', axiosConfig)
      axios.get('https://www.3dbank.xyz:13578/relay_get.api', axiosConfig)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
export const CollectionService = {

  // 나의 (카테고리)소장품리스트
  getCollectionList(category_uid, page) {
    var params = {
      "service_tp": "my_collection_list"
    };

    params.category_uid = !category_uid ? "" : category_uid;
    params.page_num = !page ? "1" : String(page);
    params.max_ret_count = "50";

    if (process.env.NODE_ENV !== 'production') {
      params.user_uid = "1"
    }

    return ApiService.post(CONTROLLER, params);
  },
  // 나의 (카테고리)소장품리스트
  getCollectionCategoryList(page) {
    var params = {
      "service_tp": "my_collection_group_by_category_list"
    };
    params.max_ret_count = "50";
    params.page_num = !page ? "1" : String(page);

    if (process.env.NODE_ENV !== 'production') {
      params.user_uid = "1"
    }
    return ApiService.post(CONTROLLER, params);
  },
  regCate(args) {

    var params = {
      "service_tp": "collection_category_reg"
    };

    if (args) {
      if (process.env.NODE_ENV !== 'production') {
        params.user_uid = "1";
      }
      if (args.category_uid) {
        params.category_uid = args.category_uid;
      }
      if (args.conts_uid) {
        params.conts_uid = args.conts_uid;
      }
      params.crud_tp = "c";
      params.category_name = args.category_name;
    }

    return ApiService.post(CONTROLLER, params);
  },
  deleteCollection(args) {

    var params = {
      "service_tp": "collection_reg"
    };

    if (args) {
      params.conts_uid = args.conts_uid;
      params.crud_tp = "d";

      if (process.env.NODE_ENV !== 'production') {
        params.user_uid = "1";
      }
    }

    return ApiService.multipart(CONTROLLER_MULTIPART, params);
  },

  moveCollection(args) {

    var params = {
      "service_tp": "collection_reg"
    };

    if (args) {
      params.ins_mv_tp = args.ins_mv_tp ? args.ins_mv_tp : "1";
      params.conts_uid = args.conts_uid;
      params.category_uid = args.category_uid;
      params.crud_tp = "c";

      if (process.env.NODE_ENV !== 'production') {
        params.user_uid = "1";
      }
    }

    return ApiService.multipart(CONTROLLER_MULTIPART, params);
  },


  registCollection(args) {

    var params = {
      service_tp: "collection_reg",
      crud_tp: "c",
      ins_mv_tp: "1",
      coll_name: args.coll_name,
      coll_desc: args.coll_desc,
      public_yn: args.public_yn,
      order_save_yn: args.order_save_yn
    };

    if (args) {
      if (process.env.NODE_ENV !== 'production') {
        params.user_uid = "1";
      }
      if (args.category_uid) {
        params.category_uid = args.category_uid;
      }

      if (args.coll_tp) {
        params.coll_tp = args.coll_tp;
      }

      if (args.video_url) {
        params.video_url = args.video_url;
      }

      if (args.delegate_file_name) {
        params.delegate_file_name = args.delegate_file_name;
      }
    }

    return ApiService.multipart(CONTROLLER_MULTIPART, params, args.files);
  },
};
export const MuseumService = {
  // 지역별 박물관
  getLocationSummaryList() {
    var params = {
      "service_tp": "hold_loc_summary_count_list"
    };
    return ApiService.post(CONTROLLER, params);
  },
  // 시대별 박물관
  getAgeSummaryList() {
    var params = {
      "service_tp": "age_summary_count_list"
    };
    return ApiService.post(CONTROLLER, params);
  },
  // 지역별 박물관 리스트
  getLocationList(area, kwd, museum_tp, page) {
    var params = {
      "service_tp": "hold_loc_list"
    };

    params.area_cd = !area ? "ALL" : area;
    params.museum_tp = !museum_tp ? "ALL" : museum_tp;

    if (kwd) {
      params.conts_word = kwd;
    }
    params.page_num = !page ? "1" : String(page);

    return ApiService.post(CONTROLLER, params);
  },
  // 박물관 상세
  getDetail(hold_loc_id, age_cd) {
    var params = {
      "service_tp": "hold_loc_list",
      "hold_loc_id": hold_loc_id,
      "area_cd": "ALL",
      "page_num": "1"
    };

    return ApiService.post(CONTROLLER, params);
  },
  // 박물관 소장품 리스트
  getContentList(hold_loc_id, age_cd, keyword, page) {
    var params = {
      "service_tp": "conts_3d_model_list",
    };

    if (hold_loc_id) {
      params.hold_loc_id = hold_loc_id;
    }

    if (age_cd) {
      params.age_cd = age_cd;
    }

    if (keyword) {
      params.conts_word = keyword;
    }
    params.page_num = !page ? "1" : String(page);

    // console.log(params);

    return ApiService.post(CONTROLLER, params);
  }
};


export const LoginService = {
  // 프로퍼티 목록
  login(args) {
    var params = {
      "service_tp": "user_login"
    };

    if (args) {
      if (args.sns_tp)
        params.sns_tp = args.sns_tp;

      if (args.p_login_id) {
        params.p_login_id = args.p_login_id;
      }
      if (args.p_pswd) {
        params.p_pswd = args.p_pswd;
      }

      if (args.kakao) {
        params.kakao = args.kakao;
      }

      if (args.naver) {
        params.naver = args.naver;
      }
      if (args.google) {
        params.google = args.google;
      }
      if (args.is_3dworld) {
        params.is_3dworld = args.is_3dworld;

      }if (args.is_google_login) {
        params.is_google_login = args.is_google_login;
      }
    }

    return ApiService.post(CONTROLLER, params);
  },

  check() {

    var params = {
      "service_tp": "user_sessioninfo"
    };

    return ApiService.post(CONTROLLER, params);
  },

  changePassword(args) {

    var params = {
      "service_tp": "change_password"
    };

    if (args) {
      params.p_pswd = args.p_pswd;
      params.new_p_pswd = args.new_p_pswd;
    }

    return ApiService.post(CONTROLLER, params);
  },
  deleteAccount(args) {

    var params = {
      "service_tp": "leave_3dbank",
      "crud_tp": "d",
      "p_login_id" : args.p_login_id
    };

    return ApiService.post(CONTROLLER, params);
  },

  findPassword(args) {

    var params = {
      "service_tp": "find_password"
    };

    if (args) {
      params.p_login_id = args.email;
      params.phone_num = args.phone;
    }

    return ApiService.post(CONTROLLER, params);
  },

  logout() {
    var params = {
      "service_tp": "user_logout"
    };

    return ApiService.post(CONTROLLER, params);
  },


  signup(args) {
    var params = {
      "service_tp": "user_reg",
      "crud_tp": "c",
      "user_tp": "2",
      "subscrip_path": "1"// 가입경로[1:3dword APP]
    };

    if (args.p_login_id)
      params.p_login_id = args.p_login_id;

    if (args.p_pswd)
      params.p_pswd = args.p_pswd;

    if (args.p_name)
      params.p_name = args.p_name;

    if (args.phone_num)
      params.phone_num = args.phone_num;

    if (args.sns_id)
      params.sns_id = args.sns_id;

    if (args.sns_tp)
      params.sns_tp = args.sns_tp;

    if (args.student_tp)
      params.student_tp = args.student_tp;

    if (args.school_name)
      params.school_name = args.school_name;

    if (args.school_code)
      params.school_code = args.school_code;

    return ApiService.post(CONTROLLER, params);
  },

  update(args) {
    var params = {
      "service_tp": "update_user_info",
    };

    if (args.p_name)
      params.p_name = args.p_name;
    if (args.phone_num)
      params.phone_num = args.phone_num;
    if (args.student_tp)
      params.student_tp = args.student_tp;
    if (args.school_name)
      params.school_name = args.school_name;
    if (args.school_code)
      params.school_code = args.school_code;

    return ApiService.multipart(CONTROLLER_MULTIPART, params, args.files);
  },

  info() {
    var params = {
      "service_tp": "user_info_by_user_uid",
    };
    return ApiService.post(CONTROLLER, params);
  }
};


export const CommonService = {
  // 프로퍼티 목록
  school(args) {
    var params = {
      "service_tp": "agency_list",
      "max_ret_count": "50",
    };

    if (args) {
      if (args.page_num) {
        params.page_num = !args.page_num ? "1" : String(args.page_num);
      }
      if (args.agency_word) {
        params.agency_word = args.agency_word;
      }
    }

    return ApiService.post(CONTROLLER, params);
  },

};


export const HologramService = {
  // 홀로그램 기기 정보 설정
  set(args) {
    var params = Object.assign({
      service_tp: 'api_hologram_mobile_set',
    }, args);

    return ApiService.post(CONTROLLER, params);
  },

  // 홀로그램 리스트
  list(args) {
    var params = {
      "service_tp": "user_hologram_list",
      holo_type: args.holo_type,
    };

    if (args.conts_word) {
      params.conts_word = args.conts_word;
    }

    if (args.only_mine) {
      params.only_mine = args.only_mine;
    }

    return ApiService.post(CONTROLLER, params);
  },

  // [20191016 KTH 추가] '내 소유의 iot 홀로그램 목록 / 소유자가 정해지지 않은 무등록 iot 홀로그램 중에서 최근에 인터넷에 연결된 홀로그램 목록'
  list_hologram(args) {
    var params = {
      service_tp: 'hologram_list_2',
      holo_type: args.holo_type,
    };

    if (args.only_mine)
      params.only_mine = args.only_mine;

    if (args.only_none_admin)
      params.only_none_admin = args.only_none_admin;

    return ApiService.post(CONTROLLER, params);
  },

  // 홀로그램 연결/해제
  connect(args) {
    var params = {
      service_tp: 'api_conn_hologram',
      holo_uid: args.holo_uid,
      isConn: args.isConn,
      pw: args.pw,
    };

    return ApiService.post(CONTROLLER, params);
  },

  start_holo_collection(args) {
    var params = {
      "service_tp": "start_hologram_disp_collection",
      category_uid: args.category_uid
    };

    return ApiService.post(CONTROLLER, params);
  },

  // 홀로그램 리스트
  detail(args) {
    var params = {
      service_tp: 'api_hologram_info',
      crud_tp: 'r',
      holo_uid: args.holo_uid
    };

    if (args.crud_tp) {
      params.crud_tp = args.crud_tp;
    }

    if (args.holo_nick_name) {
      params.holo_nick_name = args.holo_nick_name;
    }

    if (args.pub_yn) {
      params.pub_yn = args.pub_yn;
      params.holo_acc_pwd = args.holo_acc_pwd;
    }

    if (args.disp_time) {
      params.disp_time = args.disp_time;
    }

    return ApiService.post(CONTROLLER, params);
  },

  reset_admin(args) {
    var params = {
      service_tp: "hologram_reset_admin",
      holo_uid: args.holo_uid,
      act: args.act,
    };

    return ApiService.post(CONTROLLER, params);
  },
  reset_ap(args) {
    var params = {
      "service_tp": "hologram_reset_ap_mode",
      holo_uid: args.holo_uid
    };

    return ApiService.post(CONTROLLER, params);
  },

  //소장품 플레이 최종 명령자 저장 (LST_COLL_USER_UID)
  set_last_coll_user(args) {
    var params = {
      service_tp: 'update_holo_loc_lst_order',
      is_set_lst_coll: 'Y',
      reserve_conts_uid: args.reserve_conts_uid,
    };

    return ApiService.post(CONTROLLER, params);
  },

};