import { PagingEntry } from "@/entry/PagingEntry";

export class MuseumSearchEntry {
    list = new Array();
    paging = new PagingEntry();
    keyword = null;

    constructor() {

    }

}