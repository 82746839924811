import {
  OUTSIDE2D_DETAIL_ITEM,
} from "@/store/getter.type";

import {
  RESET_OUTSIDE2D,
  RESET_OUTSIDE2D_DETAIL,
  SET_OUTSIDE2D_DETAIL_TAB,
  SET_OUTSIDE2D_DETAIL_ITEM,
} from "@/store/mutations.type";

import {
  DATA_RESET,
  FETCH_OUTSIDE2D_DETAIL,
} from "@/store/action.type";

import { Outside2DService } from "@/network/api.service";

const state = {
  list: [],
  keyword: null,
  currentPage: 1,
  selectTabIndex: 1,
  outside2dDetail: {},
}

const mutations = {
  [RESET_OUTSIDE2D](state) {
    Object.assign(state, {
      list: [],
      keyword: null,
      currentPage: 1,
      outside2dDetail: {},
    });
  },
  pushList(state, payload) {
    state.list = state.list.concat(payload);
  },
  setKeyword(state, payload) {
    state.keyword = payload;
  },
  setCurrentPage(state, payload) {
    state.currentPage = payload;
  },
  [SET_OUTSIDE2D_DETAIL_TAB](state, payload) {
    state.selectTabIndex = payload;
  },
  [SET_OUTSIDE2D_DETAIL_ITEM](state, payload) {
    state.outside2dDetail = payload;
  },
  [RESET_OUTSIDE2D_DETAIL](state) {
    state.outside2dDetail = {};
  },
}

const actions = {
  [DATA_RESET]: {
    handler({ commit }) {
      commit(RESET_OUTSIDE2D);
    }
  },

  fetchList({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      Outside2DService.getList(params.kwd, state.currentPage)
      .then(res => {
        if (res.list && res.list.length) {
          let list = [];
          res.list.forEach(el => {
            list.push({
              conts_uid: el.id,
              conts_name: el.name,
              thumnail: 'https://www.3dbank.xyz:13578/relay_img.api?__url=' + encodeURIComponent((el.imgThumUriM)),
            });
          });

          commit('pushList', list);
          commit('setCurrentPage', state.currentPage + 1);

          resolve(list);
        }
        else if (res.resultCode == '0000') {
          resolve([]);
        }
        else reject();
      })
      .catch(err => {
        reject();
      });
    });
  },

  [FETCH_OUTSIDE2D_DETAIL]({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      Outside2DService.getDetailItem(params)
      .then(res => {
        if (res.list && res.list.length && res.imageList && res.imageList.list && res.imageList.list.length) {
          let detail = {
            conts_uid: res.list[0].id,
            conts_name: res.list[0].name,
            t_like_cnt: '',
            hits: '',
            //img_url: 'http://' + res.imageList.list[0].imgUri,
            img_url: 'https://www.3dbank.xyz:13578/relay_img.api?__url=' + encodeURIComponent((res.imageList.list[0].imgUri)),
            //img_url: 'https://www.3dbank.xyz:13578/relay_img.api?__url=' + encodeURIComponent(('https://www.3dbank.xyz/_img/test2.jpg')),
            //img_url: 'https://www.3dbank.xyz/downStlImg.do?file=meshconv/conts_uid_1000642/test2.jpg',
            //img_url: 'https://www.3dbank.xyz/_img/logo.png',
            compy_name: res.list[0].museumName2,
          };
          commit(SET_OUTSIDE2D_DETAIL_ITEM, detail);
          resolve(detail)
        }
        else if (res.resultCode == '0000') {
          resolve({});
        }
        else reject();
      })
      .catch(err => {
        reject();
      });
    });
  }

}
const getters = {
  getList(state, getters) {
    return state.list;
  },
  [OUTSIDE2D_DETAIL_ITEM](state, getters) {
    return state.outside2dDetail;
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
