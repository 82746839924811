import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './config/router'
import store from './store/store'
import * as VueGoogleMaps from 'vue2-google-maps'
import ApiService from "./network/api.service";
import VueCookies from 'vue-cookies'
import Modal from './plugin/modal'

document.addEventListener("backbutton", function () {
  console.log("backpress");
});

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_KEY,
    libraries: 'visualization',
    //// If you want to set the version, you can do so:
    // v: '3.26',
  }
});
Vue.use(VueCookies)
Vue.use(Modal);

ApiService.init();
//카카오톡 초기화
Kakao.init('b5893122931a7115d40e4ba4dafef111');

window['native_is_app'] = function () {
  return false;
};

window['native_call_function'] = function (name, param) {
  // 현재 컴포넌트
  window.vueApp.$children[0].$refs.router.callNativeAction(name, param);
};

window['onImageError'] = function (image) {
  // 이미지 다운로드 실패시 한번만..
  setTimeout(function () {
    console.log('이미지 에러 발생');
    image.onerror = null;
    image.src += "?" + new Date();
  }, 1500);
}

window.addEventListener('message', function (event) {
  if (!event.data.func) { return; }
  window[event.data.func](event.data.action, event.data.params);
});

window.vueApp = new Vue({
  router,
  store,
  Vuex,
  created () {
    this.$store.dispatch('loadLang');
  },
  render: h => h(App)
}).$mount('#app')
