<template>
  <div id="wrap">
    <router-view
      ref="router"
      :key="$route.path"
    />
    <alert-modal/>
  </div>
</template>

<style>
</style>

<script>
export default {
  name: "App",
  components: {},
  metaInfo: {
    meta: []
  },
  methods: {}
};
</script>