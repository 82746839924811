import axios from 'axios';

const state = {
  locales: ['ko', 'ja'],
  locale: (localStorage.getItem('locale') || navigator.language || navigator.userLanguage).substr(0, 2).toLowerCase(),
  dic: {},
};

const mutations = {
  setLang(state, payload) {
    state.dic = payload;
  },
  setLocale(state, payload) {
    state.locale = payload;
  },
};

const getters = {
  getlocale(state) {
    return (state.locales.indexOf(state.locale) < 0) ? 'ko' : state.locale;
  },
  getLang(state) {
    return state.dic;
  },
};

const actions = {
  loadLang({ commit, getters }) {
    axios.get(`${location.origin}/js/lang_${getters.getlocale}.json`)
    .then(res => {
      commit('setLang', res.data);
    })
    .catch(function(e){
      console.warn(e);
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
