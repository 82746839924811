// 초기화 할때 사용
export const DATA_RESET = `reset`;

//*** Content ***/
// 컨텐츠 데이터 가져올때 사용
export const FETCH_CONTENT_LIST = `fetchContentList`;
// 최초 서비스 프로퍼티 데이터를 가져옴
export const FETCH_PROPERTY = 'fetchProperty';
// 컨텐츠 상세 데이터
export const FETCH_CONTENT_DETAIL = `fetchContentDetail`;

//*** Outside2D ***/
export const FETCH_OUTSIDE2D_DETAIL = `fetchOutside2dDetail`;

//*** Museum ***/
// 지역별 박물관
export const FETCH_MUSEUM_MAIN_LOCATION = `fetchMuseumMainLocation`;
// 시대별 박물관
export const FETCH_MUSEUM_MAIN_AGE = `fetchMuseumMainAge`;
// 박물관 리스트
export const FETCH_MUSEUM_LIST = `fetchMuseumList`;
// 박물관 검색
export const FETCH_MUSEUM_SEARCH_LIST = `fetchMuseumSearchList`;
// 박물관 상세 데이터
export const FETCH_MUSEUM_DETAIL = `fetchMuseumDetail`;
// 박물관 상세 소장품 리스트
export const FETCH_MUSEUM_DETAIL_CONTENT = `fetchMuseumDetailContent`;

//*** COLLECTION ***/
// 소장품 리스트
export const FETCH_COLLECTION_LIST = `fetchCollectionList`;
export const FETCH_COLLECTION_CATEGORY = `fetchCollectionCategory`;
export const FETCH_COLLECTION_CATEGORY_DETAIL = `fetchCollectionCategoryDetail`;

//*** Content ***/
// 로그인 체크
export const FETCH_LOGIN_CHECK = `fetchLoginCheck`;
