import { HologramEntry } from "@/entry/HologramEntry";
import { } from "@/store/action.type";
import { SET_HOLOGRAM_DATA, SET_HOLOGRAM_DETAIL } from "@/store/mutations.type";
import { HOLOGRAM_DATA, HOLOGRAM_DETAIL } from "@/store/getter.type";


import {

} from "@/network/api.service";


const state = {
  // 홀로그램 커넥션 데이터
  data: new HologramEntry()
}

const mutations = {

  [SET_HOLOGRAM_DATA](state, payload) {

    if (payload.hologram_data) {
      state.data.hologram_data = payload.hologram_data;
    }

    if (payload.network_data) {
      state.data.network_data = payload.network_data;
    }

  }
}

const actions = {

}

const getters = {
  [HOLOGRAM_DATA](state, getters) {
    return state.data;
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}