
import { CollectionEntry } from "@/entry/CollectionEntry";
import { PagingEntry } from "@/entry/PagingEntry"

import {
    DATA_RESET,
    FETCH_COLLECTION_LIST,
    FETCH_COLLECTION_CATEGORY,
    FETCH_COLLECTION_CATEGORY_DETAIL,
} from "@/store/action.type";

import {
    SET_COLLECTION_DETAIL,
    SET_COLLECTION_LIST,
    SET_COLLECTION_CATEGORY,
    SET_COLLECTION_TAB,
    RESET_COLLECTION,
    RESET_COLLECTION_DETAIL
} from "@/store/mutations.type";

import {
    COLLECTION_LIST,
    COLLECTION_CATEGORY,
    COLLECTION_SELECT_TAB,
    COLLECTION_CATEGORY_DETAIL
} from "@/store/getter.type";

import {
    CollectionService,
    CheckApi
} from "@/network/api.service";

const state = {
    collection: new CollectionEntry(),
    collection_detail: new PagingEntry()
}

const mutations = {

    // 초기화
    [RESET_COLLECTION](state) {
        const reset_state = {
            collection: new CollectionEntry(),
            collection_detail: new PagingEntry()
        }
        Object.assign(state, reset_state)
    },
    [RESET_COLLECTION_DETAIL](state) {
        state.collection_detail = new PagingEntry();
    },

    // 소장품 메인
    [SET_COLLECTION_LIST](state, payload) {
        if (state.collection.collection.lastResponse === null) {
            state.collection.collection.lastResponse = payload.collection_list;
        } else {
            Object.assign(state.collection.collection.lastResponse, payload.collection_list)
        }

        state.collection.collection.list = state.collection.collection.list.concat(payload.collection_list);
        state.collection.collection.nextPageNum++;
    },
    [SET_COLLECTION_CATEGORY](state, payload) {
        if (state.collection.category.lastResponse === null) {
            state.collection.category.lastResponse = payload.collection_category_list;
        } else {
            Object.assign(state.collection.category.lastResponse, payload.collection_category_list)
        }

        state.collection.category.list = state.collection.category.list.concat(payload.collection_category_list);
        state.collection.category.nextPageNum++;
    },
    [SET_COLLECTION_TAB](state, payload) {
        state.collection.tabIndex = payload;
    },
    [SET_COLLECTION_DETAIL](state, payload) {
        if (state.collection_detail.lastResponse === null) {
            state.collection_detail.lastResponse = payload.collection_list;
        } else {
            Object.assign(state.collection_detail.lastResponse, payload.collection_list)
        }

        state.collection_detail.list = state.collection_detail.list.concat(payload.collection_list);
        state.collection_detail.nextPageNum++;
    }
}

const actions = {
    [DATA_RESET]: {
        root: true,
        handler({ commit }) {
            commit(RESET_COLLECTION);
            commit(RESET_COLLECTION_DETAIL);
        }
    },
    [FETCH_COLLECTION_LIST]({ commit, state }, params) {
        return new Promise((resolve, reject) => {
            // 소장품 전체

            CollectionService.getCollectionList(params.category_uid, state.collection.collection.nextPageNum).then(res => {

                if (!CheckApi.check(res)) {
                    reject(res.data);
                    return;
                }

                if (res.data.collection_list) {
                    commit(SET_COLLECTION_LIST, res.data);
                }

                resolve(res.data)
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    [FETCH_COLLECTION_CATEGORY]({ commit, state }, params) {
        return new Promise((resolve, reject) => {
            // 소장품 카테고리
            CollectionService.getCollectionCategoryList(state.collection.category.nextPageNum).then(res => {

                if (!CheckApi.check(res)) {
                    reject(res.data);
                    return;
                }

                if (res.data.collection_category_list) {
                    commit(SET_COLLECTION_CATEGORY, res.data);
                }

                resolve(res.data)
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },

    [FETCH_COLLECTION_CATEGORY_DETAIL]({ commit, state }, params) {
        return new Promise((resolve, reject) => {
            // 카테고리 상세

            CollectionService.getCollectionList(params.category_uid, state.collection_detail.nextPageNum).then(res => {

                if (!CheckApi.check(res)) {
                    reject(res.data);
                    return;
                }

                if (res.data.collection_list) {
                    commit(SET_COLLECTION_DETAIL, res.data);
                }

                resolve(res.data)
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
}
const getters = {
    [COLLECTION_LIST](state, getters) {
        return state.collection.collection.list;
    },
    [COLLECTION_CATEGORY](state, getters) {
        return state.collection.category.list;
    },
    [COLLECTION_SELECT_TAB](state, getters) {
        return state.collection.tabIndex;
    },
    [COLLECTION_CATEGORY_DETAIL](state, getters) {
        return state.collection_detail.list;
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}