import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import lang from "@/store/modules/lang"
import content from "@/store/modules/content"
import museum from "@/store/modules/museum"
import collection from "@/store/modules/collection"
import property from "@/store/modules/property"
import hologram from "@/store/modules/hologram"
import user from "@/store/modules/user"
import outside2d from "@/store/modules/outside2d"

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'


export default new Vuex.Store({
  modules: {
    lang,
    content,
    museum,
    collection,
    property,
    hologram,
    user,
    outside2d,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
