export class FilterEntry {
    //sort= 3;
    sort= Math.floor(new Date().getTime() / 1000 / (3600 * 24)) % 3 + 1;
    order= 1;
    anim= false;
    pay_tp_1= false;
    pay_tp_2= false;
    
    constructor() {

    }

}